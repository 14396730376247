import React from "react";
import { Link } from "gatsby";

function DocumentTimeline({
  type,
  date,
  title,
  subtitle,
  releaseNote,
  description,
  path,
  className,
  borderMinimumHeight = "min-h-[200px]"
}) {
  type = type.toUpperCase();

  const typeStyles = "bg-[#212562]";
  const innerCircleWidth = "w-[6px] h-[6px]";
  const typeText = "NEWSLETTER";

  const getTitleSubtitle = () => {
    return (
      <>
        <div className="text-[25px] text-white mt-3">
          <div>{title}</div>
        </div>
        <div className="text-xl text-aqua-bright text-[25px] mobile-timeline-text">{subtitle}</div>
        {releaseNote && (
          <Link to={`/release-notes/${releaseNote}`}>
            <div className="text-md text-white underline">
              {`OHIF Viewer v${releaseNote.replace("p", ".")} release note`}
            </div>
          </Link>
        )}
      </>
    );
  };

  return (
    <div className={`flex ${className}`}>
      {/* make two circles inside each other */}
      <div
        className={`flex flex-col items-center mr-4 mt-2.5 ${borderMinimumHeight}`}
      >
        <div class="flex justify-center items-center">
          <div class="relative w-[18px] h-[18px] rounded-full border-2 border-blue-bright flex justify-center items-center">
            <div
              class={`absolute rounded-full bg-aqua-bright ${innerCircleWidth}`}
            ></div>
          </div>
        </div>
        <div className="w-2 h-full border-l-2 border-aqua-pale ml-1.5 mt-2"></div>
      </div>
      <div className="flex flex-col">
        <div className="flex space-x-3 items-center ">
          <div
            className={`text-grayBright  py-[5px] px-[8px] rounded text-[16px] font-semibold ${typeStyles}`}
          >
            {typeText}
          </div>

          <span className="text-aqua-pale font-semibold text-[18px]">
            {date}
          </span>
        </div>
        <div></div>
        {path ? (
          <Link to={path}>{getTitleSubtitle()}</Link>
        ) : (
          getTitleSubtitle()
        )}
        <div className="text-white mt-2">{description}</div>
      </div>
    </div>
  );
}

export default DocumentTimeline;
