import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { format } from "date-fns";

import DocumentTimeline from "../components/DocumentTimeline";

function NewsNav({ className }) {
  const newsletterLinks = (
    <StaticQuery
      query={graphql`
        query AllHTMLNewslettersQuery {
          allHtmlContent(sort: { fields: name, order: DESC }) {
            edges {
              node {
                id
                name
                path
              }
            }
          }
        }
      `}
      render={(data) => {
        return data.allHtmlContent.edges.map(({ node }) => {
          const { id, name, path } = node;

          const date = format(new Date(name.substring(0, 10)), "MMMM yyyy");
          const type = name.includes("RELEASE") ? "RELEASE" : "NEWSLETTER";
          const releaseNote = name.includes("--RELEASE-NOTE")
            ? name.split("RELEASE-NOTE")[1]
            : null;

          let nameToUse = name.split("--")[0];

          return (
            <DocumentTimeline
              subtitle={nameToUse}
              releaseNote={releaseNote}
              path={path}
              date={date}
              key={id}
              type={type}
              className={"mb-5"}
              borderMinimumHeight="min-h-[125px]"
            />
          );
        });
      }}
    />
  );

  return (
    <div className={`${className}`}>
      <div>{newsletterLinks}</div>
    </div>
  );
}

export default NewsNav;
